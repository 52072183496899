.news-card-section {
    margin-top: 20px;
  }
  
  .news-search-header {
    padding: 20px 0;
    background-color: transparent;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .news-card {
    border: 1px solid rgba(188, 188, 188, 1);
    padding: 20px;
    transition: box-shadow 0.3s;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer !important;
  }
  
  .news-card h3 {
    font-size: 23px !important;
    font-weight: 400;
    line-height: 34px;
  }
  
  .news-card p {
    font-size: 15px !important;
    color: rgba(108, 117, 125, 1);
    margin: 20px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  
  .news-card .read-more-btn {
    margin-top: auto;
    align-self: start;
    background: transparent;
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
    border: none;
    text-align: left;
    padding: 0;
  }
  
  .news-card:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  