.webinar-detail-component {
  // Variables
  $primary-color: #0078D7;
  $dark-primary: #0056b3;
  $secondary-color: #121212;
  $light-blue: #cfe2ff;
  $white: #ffffff;
  $gray: #f5f5f7;
  $light-gray: #e9e9e9;
  $medium-gray: #6c757d;
  $dark-gray: #343a40;

  // Mixins
  @mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @mixin flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @mixin transition {
    transition: all 0.3s ease;
  }

  @mixin box-shadow {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  @mixin badge {
    display: inline-block;
    background-color: rgba($primary-color, 0.1);
    color: $primary-color;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .webinar-page {
    // Global Styles specific to the webinar component
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.6;
    color: $secondary-color;
    
    .container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }

    section {
      padding: 5rem 0;

      @media (max-width: 768px) {
        padding: 3rem 0;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 1rem;
      font-weight: 700;
      line-height: 1.2;
    }

    p {
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;
      color: $primary-color;
      @include transition;

      &:hover {
        color: $dark-primary;
      }
    }

    ul {
      list-style: none;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .btn {
      display: inline-block;
      padding: 0.75rem 1.5rem;
      border-radius: 2rem;
      font-weight: 600;
      cursor: pointer;
      text-align: center;
      @include transition;
      text-decoration: none;

      &-primary {
        background-color: $primary-color;
        color: $white;
        border: 2px solid $primary-color;

        &:hover {
          background-color: $dark-primary;
          border-color: $dark-primary;
          color: $white;
          transform: translateY(-2px);
        }
      }

      &-outline {
        background-color: transparent;
        color: $primary-color;
        border: 2px solid $primary-color;

        &:hover {
          background-color: rgba($primary-color, 0.1);
          color: $primary-color;
          transform: translateY(-2px);
        }
      }

      &-full {
        width: 100%;
      }
    }

    .section-header {
      text-align: center;
      max-width: 800px;
      margin: 0 auto 3rem;

      h2 {
        font-size: 2.25rem;
        margin: 1rem 0;

        @media (max-width: 768px) {
          font-size: 1.75rem;
        }
      }

      p {
        color: $medium-gray;
        font-size: 1.125rem;
      }
    }

    .badge {
      @include badge;
      margin-bottom: 1rem;
    }

    // Icons
    .icon {
      margin-right: 0.5rem;
      color: $primary-color;
    }

    .feature-icon {
      margin-right: 0.5rem;
      color: $primary-color;
      flex-shrink: 0;
    }

    // Hero Section
    .hero-section {
      padding-top: 8rem;
      padding-bottom: 5rem;
      background-color: $gray;
      background-image: linear-gradient(to bottom right, $white, $light-blue);
      text-align: center;

      .webinar-badge {
        @include badge;
        margin-bottom: 1rem;
      }

      .webinar-title {
        font-size: 2.75rem;
        margin-bottom: 1.5rem;
        line-height: 1.2;

        .dark-text {
          color: $secondary-color;
        }

        .blue-text {
          color: $primary-color;
        }

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }

      .webinar-meta {
        margin-bottom: 1.5rem;

        .date-time {
          display: flex;
          justify-content: start;
          gap: 2rem;
          margin-bottom: 1rem;
          
          @media (max-width: 576px) {
            flex-direction: column;
            gap: 0.5rem;
          }

          .date, .time {
            display: flex;
            align-items: center;
            
            i {
              margin-right: 0.5rem;
            }
          }
        }
      }

      .webinar-description {
        max-width: 800px;
        margin: 0 auto 2rem;
        font-size: 1.125rem;
        color: $medium-gray;
      }

      .cta-buttons {
        display: flex;
        justify-content: start;
        gap: 1rem;
        margin-bottom: 3rem;

        @media (max-width: 576px) {
          flex-direction: column;
          align-items: center;

          .btn {
            width: 100%;
            max-width: 300px;
          }
        }
      }

      .webinar-content {
        text-align: start;
      }

      .countdown-wrapper {
        padding: 4%;
        width: fit-content;
        border-radius: 0.5em;
        justify-self: center;
      }
      .countdown-container {
        max-width: 600px;
        margin: 0 auto;
        align-content: center;

        h3 {
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }

        .countdown {
          display: flex;
          justify-content: center;
          gap: 1rem;

          .countdown-item {
            background-color: $light-blue;
            border-radius: 0.5rem;
            padding: 1rem;
            min-width: 80px;
            @include flex-center;
            flex-direction: column;

            .count {
              font-size: 2rem;
              font-weight: 700;
              color: $primary-color;
            }

            .label {
              font-size: 0.875rem;
              color: $medium-gray;
            }
          }

          @media (max-width: 576px) {
            .countdown-item {
              min-width: 60px;
              padding: 0.75rem;

              .count {
                font-size: 1.5rem;
              }

              .label {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }

    // About Section
    .about-section {
      background-color: $white;

      .content-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3rem;

        .why-attend {
          h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
          }

          p {
            color: $medium-gray;
            margin-bottom: 1.5rem;
          }

          .feature-list {
            li {
              display: flex;
              align-items: flex-start;
              margin-bottom: 1rem;
              
              .feature-icon {
                margin-top: 0.25rem;
                margin-right: 0.75rem;
              }
            }
          }
        }
      }
    }

    // Speakers Section
    .speakers-section {
      padding: 5rem 0;
      background-color: #f9fafb;
      position: relative;
      overflow: hidden;

      // Add subtle background decoration
      &::before {
        content: '';
        position: absolute;
        top: -10%;
        right: -5%;
        width: 300px;
        height: 300px;
        background-color: rgba($primary-color, 0.03);
        border-radius: 50%;
        z-index: 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: -5%;
        width: 300px;
        height: 300px;
        background-color: rgba($primary-color, 0.03);
        border-radius: 50%;
        z-index: 0;
      }

      .speakers-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        position: relative;
        z-index: 1;
        
        @media (min-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .speaker-card {
        background-color: $white;
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        height: 100%;
        border: 1px solid rgba($primary-color, 0.1);
        position: relative;
        
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
        }
        
        // Add decorative effects
        &:nth-child(1)::before {
          content: '';
          position: absolute;
          top: -20px;
          left: -20px;
          width: 100px;
          height: 100px;
          background-color: rgba($primary-color, 0.1);
          border-radius: 50%;
          z-index: -1;
          filter: blur(30px);
        }
        
        &:nth-child(2)::after {
          content: '';
          position: absolute;
          bottom: -20px;
          right: -20px;
          width: 100px;
          height: 100px;
          background-color: rgba($primary-color, 0.1);
          border-radius: 50%;
          z-index: -1;
          filter: blur(30px);
        }
        
        @media (min-width: 768px) {
          flex-direction: row;
          align-items: center;
          padding: 0;
        }

        .speaker-image-container {
          width: 100%;
          padding: 1.5rem;
          
          @media (min-width: 768px) {
            width: 40%;
            padding: 1.5rem;
            padding-right: 0;
          }
          
          .speaker-image {
            aspect-ratio: 1/1;
            border-radius: 0.75rem;
            overflow: hidden;
            background: linear-gradient(to bottom right, rgba($primary-color, 0.05), rgba($primary-color, 0.1));
            border: 1px solid rgba($primary-color, 0.1);
            
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: transform 0.3s ease;
              
              &:hover {
                transform: scale(1.03);
              }
            }
          }
        }

        .speaker-info {
          padding: 1.5rem;
          padding-top: 0;
          flex: 1;
          
          @media (min-width: 768px) {
            width: 60%;
            padding: 1.5rem;
          }
          
          h3 {
            font-size: 1.25rem;
            font-weight: 700;
            margin-bottom: 0.25rem;
            color: $secondary-color;
          }
          
          .role {
            color: $primary-color;
            font-weight: 600;
            margin-bottom: 1rem;
          }
          
          .bio {
            color: $medium-gray;
            line-height: 1.6;
            font-size: 0.95rem;
          }
        }
      }
    }

    // Registration Section
    .register-section {
      background: linear-gradient(135deg, $light-gray 0%, #e9ecef 100%);
      padding: 5rem 0;
      position: relative;
      overflow: hidden;
      
      &::before {
        content: '';
        position: absolute;
        top: -100px;
        right: -100px;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: rgba($primary-color, 0.05);
        z-index: 0;
      }
      
      &::after {
        content: '';
        position: absolute;
        bottom: -100px;
        left: -100px;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: rgba($primary-color, 0.05);
        z-index: 0;
      }
      
      @media (max-width: 768px) {
        padding: 3rem 0;
      }
      
      .container {
        position: relative;
        z-index: 1;
      }
      
      .register-card {
        background-color: $white;
        border-radius: 16px;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
        padding: 3.5rem;
        max-width: 800px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 6px;
          background: linear-gradient(90deg, $primary-color 0%, lighten($primary-color, 15%) 100%);
        }
        
        @media (max-width: 768px) {
          padding: 2rem;
          margin: 0 1rem;
          border-radius: 12px;
        }
      }
      
      .register-header {
        text-align: center;
        margin-bottom: 3rem;
        
        .badge {
          display: inline-block;
          background-color: rgba($primary-color, 0.1);
          color: $primary-color;
          font-weight: 600;
          font-size: 0.9rem;
          padding: 0.5rem 1.2rem;
          border-radius: 30px;
          margin-bottom: 1.2rem;
          box-shadow: 0 2px 10px rgba($primary-color, 0.15);
        }
        
        h2 {
          font-size: 2.4rem;
          font-weight: 700;
          margin-bottom: 1.2rem;
          color: $secondary-color;
          
          @media (max-width: 768px) {
            font-size: 1.8rem;
          }
        }
        
        p {
          color: $medium-gray;
          font-size: 1.1rem;
          line-height: 1.6;
          max-width: 80%;
          margin: 0 auto;
          
          @media (max-width: 768px) {
            max-width: 100%;
            font-size: 1rem;
          }
        }
      }
      
      .registration-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
        margin-bottom: 3rem;
        
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 2rem;
        }
      }
      
      .event-details {
        .detail-item {
          display: flex;
          align-items: center;
          margin-bottom: 1.8rem;
          
          &:last-child {
            margin-bottom: 0;
          }
          
          .icon-container {
            width: 56px;
            height: 56px;
            background-color: rgba($primary-color, 0.1);
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1.2rem;
            transition: all 0.3s ease;
            
            svg {
              color: $primary-color;
            }
          }
          
          &:hover .icon-container {
            transform: translateY(-3px);
            background-color: rgba($primary-color, 0.15);
            box-shadow: 0 6px 15px rgba($primary-color, 0.2);
          }
          
          .detail-content {
            h4 {
              margin-bottom: 0.3rem;
              font-weight: 600;
              color: $secondary-color;
              font-size: 1.1rem;
            }
            
            p {
              margin-bottom: 0;
              color: $secondary-color;
              font-weight: 500;
              font-size: 1.2rem;
            }
          }
        }
      }
      
      .learning-points {
        h4 {
          font-weight: 600;
          margin-bottom: 1.5rem;
          color: $secondary-color;
          font-size: 1.1rem;
        }
        
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          
          li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 1.2rem;
            transition: all 0.2s ease;
            
            &:last-child {
              margin-bottom: 0;
            }
            
            &:hover {
              transform: translateX(5px);
            }
            
            .icon-wrapper {
              min-width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: rgba($primary-color, 0.1);
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 0.8rem;
              margin-top: 0.15rem;
              
              .feature-icon {
                color: $primary-color;
              }
            }
            
            span {
              color: $medium-gray;
              line-height: 1.5;
              font-size: 1rem;
            }
          }
        }
      }
      
      .cta-container {
        text-align: center;
        
        .register-btn {
          display: inline-block;
          padding: 1rem 3rem;
          font-size: 1.1rem;
          font-weight: 600;
          text-transform: none;
          letter-spacing: 0.5px;
          color: $white;
          background: linear-gradient(90deg, $primary-color 0%, darken($primary-color, 10%) 100%);
          border: none;
          border-radius: 50px;
          box-shadow: 0 8px 20px rgba($primary-color, 0.3);
          transition: all 0.3s ease;
          text-decoration: none;
          
          &:hover {
            transform: translateY(-3px);
            box-shadow: 0 12px 25px rgba($primary-color, 0.4);
          }
          
          &:active {
            transform: translateY(-1px);
          }
          
          @media (max-width: 768px) {
            width: 100%;
            padding: 1rem 1.5rem;
          }
        }
      }
    }
  }

  // Animations
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fade-in {
    animation: fadeIn 0.6s ease forwards;
  }
}