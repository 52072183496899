* {
  box-sizing: border-box;
}

// _master.scss
:root {
  --primary: rgba(57, 143, 255, 1);
  --blackColor: #000000;
}

// etc..

:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  box-shadow: unset !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 1) !important;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  padding: 10px 45px !important;
}

.nav-menu {
  border: none;
}

.nav-menu.collapsed {
  .menu {
    display: block;
  }

  .close-menu {
    display: none;
  }
}

.nav-menu {
  .close-menu {
    display: block;
  }

  .menu {
    display: none;
  }
}

//nav bar
.theme-header {
  position: sticky;
  top: 0;
  z-index: 99999;
  background-color: white;

  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .header-container {
    display: flex;
    align-items: center;
    padding: 12px 0px;
  }
}


//nav-wrapper {
//  display: flex;
//  position: relative;
//  flex-direction: row;
//  flex-wrap: nowrap;
//  align-items: center;
//  justify-content: space-between;
//  margin: auto;
//  width: 90%;
//  height: 80px;
//  border-radius: 0 0 15px 15px;
//  padding: 0 25px;
//  z-index: 2;
//  background: #fff;
//  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
//}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999;
}

.logo {
  height: 60px;
}

.nav-tabs {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  list-style: none;
  margin-bottom: 0px;
}

.nav-tab:not(:last-child) {
  padding: 10px 25px;
  margin: 0;
  border-right: 1px solid #eee;
}

.nav-tab:last-child {
  padding: 10px 0 0 25px;
}

.nav-tab,
.menu-btn {
  cursor: pointer;
}

.hidden {
  display: none;
}

.theme-header {
  position: sticky;
  top: 0;
  z-index: 99999;
  background-color: white;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.menu-btn {
  display: none;
}

.theme-navbar {
  width: 100%;

  .navbar-nav {
    flex-direction: row;
    align-items: center;

    li {
      a {
        color: var(--blackColor);
        padding: 5px 19px !important;
        font-weight: 600;
        font-size: 17px;

        &.active {
          color: var(--primary) !important;
        }

        Link:active {
          color: var(--primary) !important;
        }
      }
    }
  }
}

.arrow-icon {
  display: none;
}


.shadow {
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -moz-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Encode Sans", sans-serif;
}

h1 {
  font-size: 55px !important;
}

h2 {
  font-size: 45px !important;
}

h3 {
  font-size: 35px !important;
}

h4 {
  font-size: 25px !important;
}

p {
  font-size: 17px !important;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.model-btn-close {
  position: absolute;
  background-color: transparent;
  right: 10px;
  top: 10px;
  border: none;
  z-index: 99999;
}

.navbar-brand.active {
  color: var(--primary) !important;
}

.navbar-brand {
  img {
    height: 45px;
  }
}

.btn {
  font-size: 17px;
  padding: 10px 21px;
  border-radius: 0px;
}

.header-section {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;

  .navbar {
    padding: 15px 0px;
  }

  .navbar-nav {
    .nav-item {
      margin: 0px 15px;

      .nav-link {
        font-size: 18px;
        color: var(--blackColor);
        padding: 0;
        font-weight: 500;
      }
    }
  }
}

.header-theme-title {
  h1 {
    font-weight: 600;
    line-height: 75px;
    margin-bottom: 0px;
  }

  p {
    font-size: 18px !important;
    font-weight: 400;
    line-height: 35px;
    margin: 20px 0px;
  }

  span {
    color: var(--primary);
  }
}

.theme-title {
  h2 {
    text-align: start;
    font-weight: 600;
    line-height: 60px;
    margin-bottom: 0px;
  }

  p {
    text-align: justify;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 35px;
    margin: 20px 0px;
  }

  span {
    color: var(--primary);
  }
}

.home-slider {
  padding: 70px 0px;
}

.slider-optimized-content {
  display: flex;
  justify-content: space-around;
  margin-top: 70px;
  flex-wrap: wrap;

  .item-content {
    display: flex;
    align-items: center;
    margin: 10px 10px;
    justify-content: center;

    img {
      height: 45px;
      margin-right: 15px;
    }

    p {
      font-weight: 400;
      white-space: nowrap;
      margin-bottom: 0px;
    }
  }
}

// COMPANY LOGO //
.company-logo {
  background-color: var(--primary);
  padding: 30px 0px;
  position: relative;
}

.company-logo::before {
  position: absolute;
  height: 100%;
  width: 40%;
  content: "";
  top: 0;
  background: linear-gradient(90deg, rgba(57, 143, 255, 1) 44%, rgba(255, 255, 255, 0) 100%);
  z-index: 9;
}

.company-logo::after {
  position: absolute;
  height: 100%;
  width: 40%;
  content: "";
  top: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(57, 143, 255, 1) 43%);
  right: 0;
  z-index: 9;
}

.benefits-section{
    background-color: rgba(57, 143, 255, 1);
    padding: 30px 0px;
    position: relative;
}


// Enterprises Database //
.enterprises-database {
  .enterprises-card {
    border: 1px solid #F2F2EC;
    padding: 40px 30px 10px;
    background-color: white;
    position: relative;

    .icon {
      height: 60px;
      margin-bottom: 30px;
    }

    ul {
      padding: 0px;

      li {
        list-style: none;
        margin-bottom: 11px;

        img {
          height: 22px;
          margin-right: 7px;
        }
      }
    }

    h3 {
      font-size: 25px !important;
      font-weight: 500 !important;
    }

    p {
      font-size: 16px;
    }
  }

  .machines-card {
    margin-top: -80px;
  }
}


// Database Workload //
.database-workload-start {
  background-color: #E0E8FD;
  padding: 202px 0px 80px;
  margin-top: -160px;

  .workload-path {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      height: 50px;
      width: 2px;
      display: block;
      background-color: #4c90ff;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
}

.database-stap {
  display: flex;
  margin-bottom: 20px;

  span {
    border: 1px solid black;
    width: 35px;
    display: inline-block;
    height: 35px;
    text-align: center;
    margin-right: 12px;
    margin-top: 3px;
    line-height: 30px;
  }

  .content {
    flex: 1;

    h4 {
      font-size: 21px !important;
      margin-bottom: 13px;
    }

    p {
      font-size: 16px !important;
    }
  }
}

.watch-btn {
  margin-left: 45px;
}

//No Brainer//
.no-brainer-section {
  padding: 70px 0px;

  .theme-title {
    margin-bottom: 60px;
  }

  .brainer-card {
    padding: 20px 20px;
    transition: all 0.3s;
    height: 100%;

    img {
      height: 60px;
      margin-bottom: 35px;
    }

    h3 {
      font-size: 25px !important;
      margin-bottom: 12px;
    }

    h6 {
      font-size: 20px !important;
    }

    p {
      font-size: 15px !important;
      margin-top: 17px;
    }
  }
}

.brainer-card:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

//try-out-automated-section
.try-out-automated-section {
  background-color: #F8F8F8;
  padding: 70px 0px 200px;

  .theme-title {
    margin-bottom: 60px;
  }

  .no-brainer-card {
    position: relative;
    transition: all 0.3s;

    img {
      height: 50px;
      margin-bottom: 25px;
      position: relative;
      z-index: 9;
      background-color: #f8f8f8;
    }

    h3 {
      font-size: 25px !important;
    }

    p {
      font-size: 15px !important;
      margin-top: 17px;
      padding-right: 35%;
      line-height: 25px;
      font-weight: 500;
    }

    .mobile-show {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      width: 80%;
      height: 2px;
      background-color: #4c90ff;
      right: 0;
      top: 25px;
      transition: all 0.3s;
      z-index: 1;
    }
  }
}

.no-brainer-card:hover::before {
  width: 100%;
}

//Database Naturally
.database-naturally-section {
  margin-top: -140px;
  margin-bottom: 30px;

  .database-bg {
    background-image: url("../../../public/image/Database-Naturally-bg.jpg");
    background-size: cover;
    // min-height: 500px;
    padding: 90px 50px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(269.95deg, #000331 -1.25%, rgba(0, 6, 111, 0.9) 41.6%, rgba(0, 9, 168, 0.2) 69.31%);
    }
  }
}


// Customer Feedback
.customer-feedback-section {
  padding: 70px 0px;

  .theme-title {
    margin-bottom: 50px;
  }
}

.customer-feedback-card {
  border: 1px solid gainsboro;
  padding: 30px 30px;
  margin: 10px 0px 30px;
  transition: all 0.3s;

  .feedback-title {
    margin: 15px 0px 10px;
    font-weight: 600;
    display: block;
  }

  p {
    font-size: 16px !important;
    margin-bottom: 30px;
  }

  .customer-user {
    display: flex;

    h4 {
      font-size: 17px !important;
      margin-bottom: 5px;
      font-weight: 600;
    }

    span {
      font-size: 15px;
      color: #505050;
    }

    img {
      height: 48px;
      width: 48px;
      border-radius: 100px;
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}


// Customer Scenarios
.customer-scenarios-section {
  background-color: #EFEFEF;
  padding: 70px 0px;
  position: relative;

  .customer-card {
    border: 1px solid #BCBCBC;
    background-color: white;
    padding: 20px 20px;
    transition: all 0.3s;

    h3 {
      font-size: 24px !important;
      font-weight: 500 !important;
    }

    p {
      font-size: 16px !important;
    }

    img {
      height: 28px;
    }

    &:hover {
      background-color: transparent;
    }
  }

  //&::before {
  //  background-image: url('../../../public/image/Customer-Scenarios-bg-path.svg');
  //  width: 100%;
  //  height: 330px;
  //  left: 0;
  //  bottom: 0;
  //  content: "";
  //  position: absolute;
  //  background-size: contain;
  //  background-repeat: no-repeat;
  //}
}


//FAQ’s
.faq-section {
  padding: 60px 0px;

  .accordion-body {
    padding-left: 0px;

    p {
      font-size: 15px !important;
      line-height: 26px;
    }
  }

  .theme-title {
    margin-bottom: 40px;
  }

  .accordion-header {
    button {
      &::after {
        display: none !important;
      }
    }
  }

  .accordion-button {
    padding-left: 0px !important;
    border-radius: 0px !important;
    font-size: 18px;
    font-weight: 500;

    .add-icon {
      display: none !important;
    }
  }

  .accordion-button:not(.collapsed) {
    color: black !important;
    background-color: transparent !important;
    box-shadow: unset !important;
  }

  .accordion {
    border-bottom: 1px solid gainsboro;
  }

  .add-remove-icon {
    position: absolute;
    right: 0px;
  }

  .accordion-button.collapsed {
    .add-icon {
      display: block !important;
    }

    .remove-icon {
      display: none;
    }
  }
}

//questions
.questions-section {
  padding: 30px 0px 70px;

  .questions-card {
    background-color: #000672;
    padding: 30px 30px;
    position: relative;
    overflow: hidden;

    .questions-btn {
      display: flex;
      justify-content: end;
    }

    .content {
      display: flex;
      align-items: center;
    }

    img {
      //height: 130px;
    }

    .theme-title {
      h2 {
        font-size: 35px !important;
        line-height: 1.4;
      }

      span {
        font-size: 47px;
      }

      p {
        font-size: 16px !important;
      }
    }

    &::before {
      background-image: url("../../../public/image/Questions-bg-path.svg");
      width: 450px;
      height: 290px;
      right: -90px;
      bottom: 0;
      content: "";
      position: absolute;
      background-size: cover;
    }
  }
}


//Footer
.theme-footer {
  background-color: #DFE8FD;
  padding: 70px 0px 30px;
  position: relative;

  .top-scroll-section {
    display: flex;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 40px;

    .solid-border {
      flex: 1;
      border-top: 1px solid #98A5C1;
    }
  }

  .scroll-btn {
    border: 1px solid #98A5C1;
    padding: 7px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 17px;
    }

    span {
      font-size: 13px;
    }
  }

  .footer-text-content {
    span {
      margin-bottom: 20px;
      display: inline-block;
    }
  }

  .footer-link {
    label {
      font-weight: 700;
      margin-bottom: 19px;
    }

    ul {
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 10px;

        a {
          color: black;
          text-transform: capitalize;
          text-decoration: unset;
          transition: all 0.3s;

          &:focus {
            background-color: transparent !important;
          }

          &:hover {
            color: var(--primary);
          }
        }
      }
    }
  }

  .footer-input {
    display: flex;
    position: relative;

    input {
      border-bottom: 1px solid black;
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0px;
      padding: 12px 40px 12px 0px;
    }

    .arrow-btn {
      position: absolute;
      right: 0;
      padding: 10px;
      display: flex;

      img {
        height: 20px;
      }
    }
  }

  .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    p {
      font-size: 13px !important;
      margin-bottom: 0px;
    }

    a {
      margin: 0px 2px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;

      &:hover {
        background-color: white;
        border-radius: 100px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }
  }

  .footer-accordion {
    .accordion {
      border-bottom: 1px solid #BCBCBC;

      .accordion-item {
        border: none !important;

        .accordion-button {
          background-color: #e0e8fe !important;
          padding: 5px 0px;
          color: black;
        }

        .accordion-body {
          background-color: #e0e8fe !important;
          padding: 5px 0px;
        }
      }
    }
  }

  .footer-accordion {
    display: none;
  }

}

//breadcrumb-menu
.breadcrumb-menu {
  padding: 40px 0px 10px;

  .menu {
    padding: 0;
    margin: 0;
    display: flex;

    li {
      list-style: none;
      margin-right: 12px;

      a {
        color: #6A6A6A;
        text-decoration: unset;
      }
    }

    .active {
      color: #000000;
      font-weight: 500;
    }
  }
}

//About
.about-slider {
  padding: 30px 0px 70px;
  position: relative;

  //&::before {
  //  background-image: url("../../../public/image/about-banner-shap-two.svg");
  //  width: 150px;
  //  height: 540px;
  //  right: 0;
  //  bottom: 0;
  //  content: "";
  //  position: absolute;
  //  background-size: contain;
  //  background-repeat: no-repeat;
  //}
}

//Our story
.our-story-section {
  background-color: rgba(248, 248, 248, 1);
  padding: 70px 0px;
  position: relative;

  &::before {
    background-image: url("../../../public/image/our-story-bg-shape.svg");
    width: 450px;
    height: 100%;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    transform: translate(0%, -50%);
    top: 50%;
  }

  .theme-title {
    label {
      font-weight: 600;
      margin-bottom: 12px;
      font-size: 18px;
    }

    p {
      font-size: 16px !important;
    }
  }

  .date-card {
    display: flex;

    h6 {
      font-weight: 600 !important;

      span {
        color: var(--primary);
      }
    }

    .date-content {
      h4 {
        font-weight: 600;
      }

      p {
        text-align: justify;
        font-size: 16px !important;
        margin-top: 11px;
        line-height: 28px;
        margin-bottom: 45px;
      }

      label {
        font-weight: 500;
      }
    }

    .left-icon {
      margin-right: 20px;
      position: relative;

      img {
        width: 60px;
        height: 60px;
        padding: 15px;
        border: 1px solid #BCBCBC;
        background-color: white;
        z-index: 9;
        position: relative;
      }

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: #BCBCBC;
        left: 50%;
      }
    }
  }
}

.user-name {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  p {
    margin: 0;
    font-weight: 700;
  }
}


//Meet our
.meet-our-section {
  padding: 70px 0px;

  .theme-title {
    margin-bottom: 40px;
  }
}

.meet-our-card {
  border: 1px solid #bcbcbc;
  padding: 40px 20px 20px;
  text-align: center;
  transition: all 0.3s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.user-image {
  position: relative;
  display: inline-block;

  &::before {
    background-image: url("../../../public/image/bg-1.png");
    width: 110%;
    height: 110%;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  &::after {
    background-image: url("../../../public/image/bg-2.png");
    width: 100%;
    height: 50%;
    right: 0;
    bottom: 0px;
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//team-tursio-section
.team-tursio-section {
  padding: 0px 0px 50px;

  .team-tursio-bg {
    background-image: url("../../../public/image/team-pic-new.png");
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      background: linear-gradient(0deg, #000672 -16.9%, rgba(0, 6, 114, 0) 45.1%);
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
    }

    h2 {
      color: transparent;
      font-weight: 700;
      font-size: 85px !important;
      -webkit-text-stroke-width: 0.5px;
      -webkit-text-stroke-color: white;
      letter-spacing: 4.3px;
      position: absolute;
      bottom: -15px;
      margin: 0;
    }
  }
}

//Join Tursio
.join-tursio-section {
  background-color: rgba(248, 248, 248, 1);
  position: relative;
  padding: 70px 0px;
  margin-bottom: 40px;
  overflow: hidden;

  .join-cube-one {
    //background-image: url("../../../public/image/join-tursio-bg-shape-one.svg");
    width: 28%;

    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    left: 56%;
    top: -15%;
    transform: translate(0%, -75%);

  }

  .join-cube-tow {
    //background-image: url("../../../public/image/join-tursio-bg-shape-two.svg");
    width: 31%;
    height: 68%;
    right: 0;
    bottom: -100%;
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    left: 45%;


  }

  .theme-title {
    label {
      font-size: 26px;

      b {
        color: var(--primary);
      }
    }
  }

  .join-btn {
    display: flex;
    justify-content: end;
    align-items: end;
  }
}

//Customer Slider
.customer-slider {
  padding: 60px 0px 30px;

  .customer-feedback-card {
    background-color: rgba(223, 232, 253, 1);
    padding: 20px 20px;
  }

  .customerHomeSlider {
    height: 500px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 130px;
      top: 0;
      background: linear-gradient(180deg, #FFFFFF 13%, rgba(255, 255, 255, 0.1) 100%);
      z-index: 9;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 170px;
      bottom: 0;
      z-index: 9;
      background: linear-gradient(0deg, #FFFFFF 13%, rgba(255, 255, 255, 0.1) 100%);

    }
  }
}


.customer-company-logo {
  background-color: rgba(248, 248, 248, 1);

  &::before {
    background: linear-gradient(90deg, #f8f8f8 44%, rgba(255, 255, 255, 0) 100%) !important;
    display: none;
  }

  &::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fcfcfc 43%) !important;
    display: none;
  }
}


//Case studies Start
.case-studies-section {
  padding: 70px 0px;

  .theme-title {
    label {
      color: var(--primary);
      margin-bottom: 10px;
      font-size: 20px;
    }
  }

  .case-studies-card {
    display: flex;
    border: 1px solid rgba(204, 199, 191, 1);
    transition: all 0.3s;
    overflow: hidden;
    position: relative;
    margin: 0px 10px;

    .left-content {
      flex-basis: 65%;
      padding: 10px 20px;
      transition: all 0.3s;

      .logo {
        height: 60px;
      }

      .content {
        border-top: 1px solid black;
        padding-top: 20px;

        h4 {
          font-size: 20px !important;
          margin: 20px 0px;
          line-height: 30px;
          font-weight: 600;
        }

        P {
          font-size: 15px !important;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
        }
      }
    }

    .right-content {
      flex-basis: 35%;
      transition: all 0.3s;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

//.case-studies-card:hover .right-content {
//  flex-basis: 100%;
//}
.case-studies-card:hover .hover-container {
  bottom: 0 !important;
}

.theme-title .title-center {
  text-align: center;
  font-size: 2em;
  font-weight: 600;
}

.theme-title .title-left {
  text-align: left;
  font-size: 2em;
  font-weight: 600;
}

.hover-container {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #161616 -0.75%, rgba(49, 48, 49, 0.8) 109.05%);
  bottom: -100%;
  transition: all 0.3s;
  left: 0;

  .left-content {
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
      border-top: 1px solid white !important;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: end;
    }

    .download-icon {
      color: white;
      text-decoration: auto;
      font-size: 14px !important;

      img {
        width: 30px;
        margin-left: 5px;
      }
    }
  }
}

.case-studies-slider-body {
  margin-top: -110px;

  .caseStudies {
    padding-top: 140px;
  }

  .swiper-button-next {
    position: absolute;
    top: 25px;
    right: 21%;
  }
}

// Blog Slider
.blog-slider-section {
  background-color: transparent;
  padding: 40px 0px 40px;
}


.modal-content {
  border-radius: 5px;
  overflow: hidden;

  .modal-body {
    line-height: 1;
  }
}

.blog-section {
  margin-top: -100px;

  .blog-card {
    display: flex;
    background-color: rgba(244, 245, 245, 1);
    height: 100%;
    transition: all 0.3s;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .blog-left-image {
      width: 230px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .blog-right-content {
      padding: 30px;
      flex: 1;

      .date-time {
        margin-bottom: 28px;

        span {
          margin-right: 18px;
          font-size: 14px !important;
        }
      }

      .theme-title {
        p {
          font-size: 16px !important;
          line-height: 27px;
        }

        h3 {
          font-size: 25px !important;
          line-height: 34px;
        }
      }

      .blog-user {
        display: flex;
        align-items: center;

        a {
          border: 1px solid rgba(57, 143, 255, 1);
          padding: 3px 16px;
          border-radius: 5px;
          font-size: 13px;
          text-decoration: unset;
          transition: all 0.3s;

          &:hover {
            background-color: #4c90ff;
            color: white;
          }
        }

        h6 {
          margin: 0px 25px 0px 15px;
        }

        img {
          height: 42px;
          border-radius: 100px;
        }
      }
    }
  }
}


//Tab Blog Start
.blog-body-section {
  margin-top: 50px;

  .blog-nav-body {
    width: 100%;

    .nav-tabs {
      border: unset !important;
    }

    .nav-link {
      color: var(--blackColor);
    }

    .activeLink {
      background-color: red !important;
    }

    .nav-link:active {
      background-color: red !important;
      color: white !important;
      border: unset !important;
      border-radius: 0px !important;
    }

    .tab-content {
      margin-top: 40px;
    }

    .blog-search {
      position: relative;
      width: 100%;

      img {
        position: absolute;
        left: 13px;
        top: 11px;
      }

      input {
        padding: 10px 10px 10px 50px;
        border: 1px solid rgba(188, 188, 188, 1);
        width: 100%;
      }

      ::placeholder {
        color: var(--blackColor);
      }
    }

    .mobile-blog-dropdown {
      .blog-dropdown {
        border: 1px solid #bcbcbc;
        justify-content: space-between;

        &::after {
          display: none;
        }
      }
      .blog-dropdown img {
        margin-left: 8px;
      }
      
    }

  }

  .pagination-body {
    padding: 40px 0px 20px;
    text-align: center;

    .pagination {


      a {
        color: var(--blackColor);
        float: left;
        padding: 8px 15px;
        text-decoration: none;
      }

      a.active {
        background-color: rgba(249, 245, 255, 1);
        color: rgba(57, 143, 255, 1);
      }
    }
  }
}

.blog-tab-card {
  background-color: rgba(233, 233, 233, 1);
  height: 100%;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  img {
    height: 245px;
    object-fit: cover;
  }

  .blog-contant {
    padding: 25px 25px;
    display: flex;
    flex-direction: column;

    .blog-date {
      span {
        font-size: 13px;
        margin-right: 13px;
        font-weight: 600;
      }
    }

    .blog-title {
      flex: 1;

      h3 {
        margin: 20px 0px;
        font-size: 24px !important;
        color: #121416;
        line-height: 35px;
      }
    }

    p {
      font-size: 15px !important;
      margin: 0;
      color: #6c757d;
      line-height: 23px;
    }
  }
}


//Blog Slider
.blog-post-slider-section {
  background-color: #e8e8e8;
  padding: 40px 15px 130px;
  margin-top: 20px;

  .mobile-date-with-published {
    display: none;

    span {
      margin-right: 15px;
      font-weight: 500;
    }
  }

  .blog-user {
    display: flex;
    align-items: center;

    a {
      border: 1px solid rgba(57, 143, 255, 1);
      padding: 3px 16px;
      border-radius: 5px;
      font-size: 13px;
      text-decoration: unset;
      transition: all 0.3s;

      &:hover {
        background-color: #4c90ff;
        color: white;
      }
    }

    h6 {
      margin: 0px 25px 0px 15px;
    }

    img {
      height: 42px;
      border-radius: 100px;
    }
  }

  .share-blog {
    display: flex;
    align-items: center;

    img {
      margin-left: 11px;
      height: 28px;
    }
  }

  .details-body {
    margin: 30px 0px;
    display: flex;

    .details-read {
      margin-right: 25px;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      span {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
}

.pagination-body {
  border-top: 1px solid;
  padding: 15px 0px;
  margin-top: 60px;

  .pagination {
    display: flex;
    justify-content: center;

    a {
      color: var(--blackColor);
      float: left;
      padding: 8px 15px;
      font-weight: 500;
      text-decoration: none;
      display: flex;
      align-items: center;

      img {
        margin-bottom: 0px;
      }
    }
  }
}

.post-container {
  margin-top: -100px;
  padding-bottom: 50px;

  .blog-detail {
    .references-title {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 18px;
      margin-top: 20px;
    }

    img {
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
    }
  }

  .pagination-body {
    .pagination {
      justify-content: space-between;
    }
  }
}


//related-post-section
.related-post-section {
  background-color: rgba(248, 248, 248, 1);
  padding: 70px 0px 30px;

  .theme-title {
    margin-bottom: 40px;
  }

  .relatedPost {
    .swiper-slide {
      padding-bottom: 50px !important;
    }
  }
}

.blog-questions-section {
  padding-top: 70px;
}

//Related post
.blog-header-section {
  margin-top: 20px;

  .blog-inner-container {
    display: flex;
    align-items: center;
  }

  .blog-search-header {
    padding: 20px 27px;
    background-color: transparent;
    margin-bottom: 40px;


    .blog-search {
      position: relative;
      flex-basis: 70%;
      margin-right: 25px;

      img {
        position: absolute;
        left: 13px;
        top: 11px;
      }

      input {
        padding: 12px 10px 12px 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 1);
        width: 100%;
        background-color: transparent;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
      }
    }
  }

  .blog-dropdown {
    flex-basis: 30%;

    .blog-btn {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 1);
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 0px;
      background-color: transparent;
      border-left: 0px;
      border-top: 0px;
      border-right: 0px;

      &::after {
        display: none !important;
      }
    }
  }
}

//Related post
.research-card-section {
  margin-top: 20px;

  .research-inner-container {
    display: flex;
    align-items: center;
  }

  .research-search-header {
    padding: 20px 27px;
    background-color: transparent;
    margin-bottom: 40px;


    .research-search {
      position: relative;
      flex-basis: 70%;
      margin-right: 25px;

      img {
        position: absolute;
        left: 13px;
        top: 11px;
      }

      input {
        padding: 12px 10px 12px 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 1);
        width: 100%;
        background-color: transparent;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
      }
    }
  }

  .research-card {
    border: 1px solid rgba(188, 188, 188, 1);
    padding: 20px 20px 30px;
    transition: all 0.3s;
    height: 100%;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 23px !important;
      font-weight: 400;
      line-height: 34px;
      flex: 1;
    }

    p {
      font-size: 15px !important;
      font-weight: 400;
      line-height: 23.95px;
      margin: 20px 0px 30px;
      color: rgba(108, 117, 125, 1);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .research-contant {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }

      b {
        margin-right: 6px;
      }

      b.active {
        color: var(--primary);
        font-size: 15px;
      }
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }

  .research-dropdown {
    flex-basis: 30%;

    .research-btn {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 1);
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 0px;
      background-color: transparent;
      border-left: 0px;
      border-top: 0px;
      border-right: 0px;

      &::after {
        display: none !important;
      }
    }
  }
}

.research-pagination {
  border-top: 0px;
  margin-top: 10px;

  .pagination {
    justify-content: center;

    .page {
      display: flex !important;
      color: var(--blackColor) !important;

      a {
        color: black;
        text-decoration: unset;
        font-weight: 600;

        img {
          margin-right: 10px;
        }

      }

      .previous-next-btn {
        display: flex;
        align-items: center;

        img {
          margin: 0px 10px;
        }
      }

      button {
        background-color: transparent;
        color: var(--blackColor) !important;
        border: unset !important;
      }
    }

    .active {
      .page-link {
        background-color: rgba(249, 245, 255, 1);
        color: rgba(57, 143, 255, 1);
      }
    }
  }
}

.news-pagination {
  border-top: 0px;
  margin-top: 10px;

  .pagination {
    justify-content: center;

    .page {
      display: flex !important;
      color: var(--blackColor) !important;

      a {
        color: black;
        text-decoration: unset;
        font-weight: 600;

        img {
          margin-right: 10px;
        }

      }

      .previous-next-btn {
        display: flex;
        align-items: center;

        img {
          margin: 0px 10px;
        }
      }

      button {
        background-color: transparent;
        color: var(--blackColor) !important;
        border: unset !important;
      }
    }

    .active {
      .page-link {
        background-color: rgba(249, 245, 255, 1);
        color: rgba(57, 143, 255, 1);
      }
    }
  }
}

.contact-title-header {
  background-color: transparent;
  padding: 37px 15px;
  text-align: center;
  margin-top: 20px;
  position: relative;
}

.contact-form-title {
  margin-top: 80px;
  margin-bottom: 40px;
  font-weight: 600;
}

.contact-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  label {
    font-size: 15px;
  }

  input {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
  }
}

.shape-contact-image {
  margin-top: -128px;
  width: 100%;
}

.border-spacing {
  margin: 55px 0px 10px;
}


.Workload-path {
  animation: workload 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -9s);
  animation-iteration-count: 5;
  animation-fill-mode: both;
}

@keyframes workload {
  to {
    transform: rotate(360deg);
  }
}


.database-image {
  display: flex;
  padding: 73px 20% 73px 30%;
  position: relative;

  //align-items: center;


  .database-one {
    position: absolute;
    left: 0;
    bottom: 3%;

    width: 30%;

  }


  .database-two {
    width: 100%;

  }

  .database-three {
    width: 20%;
    position: absolute;
    right: 0;
    top: 13%;

  }
}


.c-animeted-grfics {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
}

.customer-scenarios-cube-one {
  width: 330px;
}

.customer-scenarios-cube-tow {
  transform: translate(0, 75%);
  animation: customerAnimation 1s linear infinite reverse;
  animation-play-state: paused;
  animation-delay: calc(var(--customerCube) * -4s);
  animation-iteration-count: 5;
  animation-fill-mode: both;
  height: 193px;
  margin-left: -19px;
}

@keyframes customerAnimation {
  to {
    transform: translate(0, 30%);
  }
}


//.u-blue-cube {
//  position: absolute;
//  transform: translate(0px, 90%);
//  animation: unlimitedCubeAnimation 1s linear infinite reverse;
//  animation-play-state: paused;
//  animation-delay: calc(var(--unlimitedCube) * -4s);
//  animation-iteration-count: 5;
//  animation-fill-mode: both;
//}
//
//@keyframes unlimitedCubeAnimation {
//  to {
//    transform: translate(0px, -180%);
//  }
//}


.unlimited-cube-container {
  width: 147px;
  text-align: center;
  justify-content: center;
  padding: 73px 0;
}


.database-mobile-show {
  display: none;
}

.enterprises-body {
  position: relative;
  height: 500px;
  overflow: hidden;

  .enterprises-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .enterprises-img-two {
    //animation: animationTwo 1s linear infinite reverse;
    //animation-play-state: paused;
    //animation-delay: calc(var(--homePageCube) * -4s);
    //animation-iteration-count: 5;
    //animation-fill-mode: both;
    position: absolute;
    top: 20%;
    left: 47%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .enterprises-img-three {
    //animation: animationThree 1s linear infinite;
    //animation-play-state: paused;
    //animation-delay: calc(var(--homePageCube) * -4s);
    //animation-iteration-count: 4;
    //animation-fill-mode: both;
    transform: translate(-50%, 0%);
    position: absolute;
    top: 34%;
    left: 11%;
  }

  .enterprises-img-four {
    //animation: animationFour 1s linear infinite reverse;
    //animation-play-state: paused;
    //animation-delay: calc(var(--homePageCube) * -4s);
    //animation-iteration-count: 4;
    //animation-fill-mode: both;
    position: absolute;
    top: 30%;
    left: 70%;
    transform: translate(-50%, 0%);
  }

  .enterprises-img-five {
    //animation: animationFive 1s linear infinite reverse;
    //animation-play-state: paused;
    //animation-delay: calc(var(--homePageCube) * -4s);
    //animation-iteration-count: 4;
    //animation-fill-mode: both;
    transform: translate(-50%, 0%);
    top: 15%;
    left: 90%;
    position: absolute;
  }

  //@keyframes animationTwo {
  //  to {
  //    transform: translateY(-100px) rotate(30deg);
  //  }
  //}
  //
  //@keyframes animationThree {
  //  to {
  //    transform: translateY(100px) translateX(100px) rotate(197deg);
  //  }
  //}
  //
  //@keyframes animationFour {
  //  to {
  //    transform: translateX(100px) rotate(289deg);
  //  }
  //}
  //
  //@keyframes animationFive {
  //  to {
  //    transform: translateY(-100px) rotate(223deg);
  //  }
  //}
}

.about-slide-cube {
  position: relative;
}

.about-slide-cube img {
  width: 31.3%;
  position: absolute;
}

img.about-shap-two-cube {
  position: absolute;
  right: 0;
  bottom: 9px;
  width: 7.4%;
}

img.about-cube-tow {
  top: 45%;
  left: 10%;
}

img.about-cube-three {
  right: 10.5%;
  top: 32%;
}

img.about-cube-one {
  left: 27%;
  top: 10%;
}

.about-slide-cube img {
  width: 31.3%;
  position: absolute;
}


.about-banner-shape {
  animation: aboutBanner 1s linear infinite reverse;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -8s);
  animation-iteration-count: 8;
  animation-fill-mode: both;
  position: unset !important;
  visibility: hidden;
}

@keyframes aboutBanner {
  to {
    transform: scale(0.8);
  }
}

.blog-page {
  animation-name: fade;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.customer-page {
  animation-name: slide;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes slide {
  0% {
    transform: translate(0px, 70px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide {
  0% {
    transform: translate(0px, 70px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@-o-keyframes slide {
  0% {
    transform: translate(0px, 70px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes slide {
  0% {
    transform: translate(0px, 70px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.about-page {
  animation-name: slideTwo;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes slideTwo {
  0% {
    transform: translate(70px, 70px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide {
  0% {
    transform: translate(70px, 70px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@-o-keyframes slide {
  0% {
    transform: translate(70px, 70px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes slide {
  0% {
    transform: translate(70px, 70px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}


@media screen and (max-width: 1199px) {
  .database-image{
    padding: 73px 0 !important;
  }
  .database-mobile-show {
    display: block !important;
    width: 100%;

  }
  .animation-img {
    display: none;
  }

}

@media screen and (max-width: 1024px) {
  .header-theme-title h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .company-logo::before,
  .company-logo::after {
    display: none;
  }

  //.enterprises-body {
  //  .enterprises-img-two {
  //    top: 42%;
  //    left: 48%;
  //    height: 120px;
  //  }
  //
  //  .enterprises-img-three {
  //    top: 37% !important;
  //    left: 15% !important;
  //    width: 90px !important;
  //  }
  //
  //  .enterprises-img-four {
  //    top: 47% !important;
  //    left: 64% !important;
  //    width: 100px !important;
  //  }
  //
  //  .enterprises-img-five {
  //    top: 42% !important;
  //    left: 70% !important;
  //  }
  //
  //  @keyframes animationTwo {
  //    to {
  //      transform: translateY(-100px) rotate(37deg);
  //    }
  //  }
  //
  //  @keyframes animationThree {
  //    to {
  //      transform: translateY(100px) translateX(100px) rotate(160deg);
  //    }
  //  }
  //
  //  @keyframes animationFour {
  //    to {
  //      transform: translateX(100px) rotate(50deg);
  //    }
  //  }
  //
  //  @keyframes animationFive {
  //    to {
  //      transform: translateY(-100px) rotate(298deg);
  //    }
  //  }
  //}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .customer-slider .customerHomeSlider {
    height: 550px !important;
  }
  .enterprises-body {
    width: 636px;
  }
  .enterprises-database {
    padding-top: 40px;
  }
  .leptop-layout {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  .customer-feedback-card {
    margin: 10px 10px 30px;
  }

  .customer-slider .customerHomeSlider::before,
  .customer-slider .customerHomeSlider::after {
    display: none !important;
  }

  .customer-slider .customerHomeSlider {
    height: unset !important;
  }

  .enterprises-database {
    padding: 30px 0px 0px;
  }

  .database-workload-start {
    padding: 190px 0px 40px;
  }

  .no-brainer-section {
    padding: 15px 0px 0px;
  }

  .try-out-automated-section {
    padding: 15px 0px 140px;
  }

  .database-naturally-section .database-bg {
    padding: 40px 50px;
  }

  .customer-feedback-section {
    padding: 20px 0px 0;
  }

  .customer-feedback-section .theme-title {
    margin-bottom: 15px;
  }

  .customer-scenarios-section {
    padding: 15px 0px 15px;
  }

  .faq-section {
    padding: 15px 0px;
  }

  .questions-section .questions-card .theme-title h2 {
    font-size: 29px !important;
    line-height: 37px !important;
  }

  .theme-footer {
    padding: 40px 0px 30px;
  }

  .email-input {
    margin-top: 40px;
  }

  .team-tursio-section .team-tursio-bg h2 {
    font-size: 70px !important;
    bottom: 9px;
  }

  h2 {
    font-size: 38px !important;
    line-height: 53px !important;
  }

  .about-banner-shape,
  .about-slider::before,
  .our-story-section::before {
    display: none;
  }

  .team-tursio-section .team-tursio-bg {
    height: 320px;
  }

  .our-story-section {
    padding: 15px 0;
  }

  .our-story-date-card:first-child {
    margin-top: 30px;
  }

  .about-slider {
    padding: 15px 0 0;
  }

  .meet-our-section {
    padding: 15px 0 0;
  }

  .contact-title-header {
    padding: 25px 15px;
  }

  .shape-with-title {
    display: flex;
    flex-direction: column-reverse;

    .shape-contact-image {
      margin-top: 0px;
    }
  }

  .border-bottom {
    display: none;
  }

  .form {
    button {
      width: 100%;
    }
  }

  .case-studies-section {
    padding: 15px 0px 0 !important;

    .swiper-button-next {
      display: none !important;
    }

    .caseStudies {
      padding-top: 150px;
    }
  }

  .blog-section {
    .blogCard {
      .swiper-wrapper {
        padding-bottom: 45px !important;
      }
    }

    .blog-card {
      display: unset !important;

      .blog-right-content {
        background-color: rgba(244, 245, 245, 1) !important;
      }

      .blog-left-image {
        width: 100% !important;
      }
    }
  }

  //Menu
  .nav-container {
    position: fixed;
    display: none;
    overflow-y: auto;
    z-index: -1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
  }

  .menu-btn {
    display: block !important;
  }

  .arrow-icon {
    display: block !important;
    margin-right: 15px;

  }

  .theme-header {
    .nav-tabs {
      flex-direction: column;
      align-items: flex-end;
      margin-top: 80px;
      width: 100%;
    }
  }

  .nav-tab:not(:last-child) {
    padding: 20px 25px;
    margin: 0;
    border-right: unset;
    border-bottom: 1px solid #f5f5f5;
  }

  .nav-tab:last-child {
    padding: 15px 25px;
  }

  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    box-shadow: 1px 2px 6px gainsboro;
  }

  .menu-btn {
    position: relative;
    display: block;
    margin: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 999;
    background-color: #ffffff;
  }

  .menu-btn .menu {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #111;
  }

  .menu-btn .close-menu {
    display: none !important;
  }

  #menuToggle:checked + .menu-btn .open-menu {
    transition: transform 0.2s ease;
    display: none;
  }

  #menuToggle:checked + .menu-btn {
    .close-menu {
      display: block !important;
    }
  }

  #menuToggle:checked ~ .nav-container {
    z-index: 99;
    display: flex;
    animation: menu-slide-left 0.3s ease;
    flex-direction: column;
    height: 100Vh;
    justify-content: end;
    //padding-top: 30px;

    .navbar-nav {
      flex-direction: column !important;
      align-items: start !important;
      padding: 25px 25px;

      .nav-item {
        width: 100%;
        border-bottom: 1px solid gainsboro !important;

        .nav-link {
          padding: 17px 0px !important;
          display: flex;
          align-items: center;
        }
      }

      .btn {
        width: 100%;
        margin-left: 0px !important;
        background-color: var(--primary) !important;
        border: var(--primary) !important;
        color: white;
        margin-top: 30px !important;
      }
    }
  }

  @keyframes menu-slide-left {
    0% {
      transform: translateX(200px);
    }

    to {
      transform: translateX(0);
    }
  }

  .navbar-brand img {
    height: 35px;
  }
}

//Terms of Use

.terms-body {
  padding: 40px 0px 20px;

  p {
    font-size: 17px !important;
    color: #545454 !important;
    line-height: 33px;
  }

  .italic {
    font-style: italic;
  }

  .terms-list {
    label {
      font-weight: 600;
    }

    li {
      h4 {
        font-weight: 600 !important;
        font-size: 18px !important;
        margin-bottom: 15px;
      }

      p {
        font-size: 17px !important;
        color: #545454 !important;
        line-height: 33px;
      }

      ul {
        list-style-type: circle;

        label {
          font-weight: 600;
          margin: 20px 0px 15px;
          font-size: 18px;

        }
      }
    }
  }
}

.terms-body li::marker {
  font-weight: 600 !important;
  font-size: 18px !important;
}

@media screen and (max-width: 768px) {
  .home-slider {
    padding: 20px 0px 30px;
  }

  .slider-optimized-content {
    margin-top: 30px;
  }

  .company-logo::before,
  .company-logo::after {
    width: 20% !important;
  }

  .company-logo {
    .swiper-slide {
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .blog-card-section {
    .blog-inner-container {
      display: flex;
      flex-direction: column-reverse;

      .blog-dropdown {
        flex-basis: 100%;
        margin: 10px 0px 20px;
      }

      .blog-search {
        flex-basis: 100%;
        margin-right: 0px;
      }
    }

    .blog-search-header {
      padding: 15px 15px 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .research-card-section {
    .research-inner-container {
      display: flex;
      flex-direction: column-reverse;

      .research-dropdown {
        flex-basis: 100%;
        margin: 10px 0px 20px;
      }

      .research-search {
        flex-basis: 100%;
        margin-right: 0px;
      }
    }

    .research-search-header {
      padding: 15px 15px 20px;
    }
  }

  .header-theme-title {
    text-align: center;
  }

  .solution-header-vectors{
    display: none;
  }

  .share-blog {
    display: none !important;
  }

  .theme-title{
    text-align: center;
  }
  
  .enterprises-body {
    .enterprises-img-two {
      top: 44%;
      left: 48%;
      height: 120px;
    }

    .enterprises-img-three {
      top: 38% !important;
      left: 5% !important;
      width: 90px !important;
    }

    .enterprises-img-four {
      top: 48% !important;
      left: 68% !important;
      width: 100px !important;
    }

    .enterprises-img-five {
      top: 44% !important;
      left: 78% !important;
    }

    @keyframes animationTwo {
      to {
        transform: translateY(-100px) rotate(37deg);
      }
    }

    @keyframes animationThree {
      to {
        transform: translateY(100px) translateX(100px) rotate(182deg);
      }
    }

    @keyframes animationFour {
      to {
        transform: translateX(100px) rotate(40deg);
      }
    }

    @keyframes animationFive {
      to {
        transform: translateY(-100px) rotate(248deg);
      }
    }
  }

}

@media screen and (max-width: 575px) {

  .research-card-section .research-card p {
    font-size: 14px !important;
    margin: 10px 0px 15px !important;
  }
  .header-theme-title {
    text-align: center;
  }
  .solution-header-vectors{
    display: none;
  }
  .header-theme-title h1 {
    font-size: 33px !important;
    line-height: 44px;
  }

  .header-theme-title p {
    font-size: 16px;
    line-height: 30px;
    margin: 10px 0px;
  }
  .theme-title{
    text-align: center;
  }
  .slider-optimized-content {
    margin-top: 15px;
    flex-wrap: unset !important;

    .item-content {
      flex-direction: column;
      text-align: center;

      p {
        white-space: unset !important;
        font-size: 14px !important;
        margin-top: 12px;
      }

      img {
        height: 37px;
        margin-right: 0px;
      }
    }
  }

  .home-slider button {
    width: 49% !important;
    margin: 0 !important;
    padding: 10px !important;
  }

  h2 {
    font-size: 31px !important;
    line-height: 43px !important;
  }

  .no-brainer-section {
    .brainer-card {
      text-align: center;
    }
  }

  .try-out-automated-section {
    .theme-title {
      text-align: center;
    }

    .no-brainer-card {
      text-align: center;
      margin-bottom: 35px;

      &::before {
        display: none !important;
      }

      p {
        padding-right: 0px !important;
        margin-top: 0px;
      }

      .mobile-show {
        display: block !important;
      }
    }
  }

  .database-naturally-section .database-bg {
    padding: 20px 15px;
    text-align: center;
  }

  .desktop-show,
  .mobile-block {
    display: none !important;
  }

  .theme-title p {
    font-size: 16px;
    line-height: 31px;
    margin: 10px 0px;
  }

  .customer-feedback-section .customer-feedback-card {
    padding: 20px 20px;
  }

  .case-studies-section {
    padding: 40px 0px;
  }

  .customer-scenarios-section {
    .customer-card {
      background-color: #efefef;
    }

    &::before {
      display: none;
    }
  }

  .questions-section .questions-card {
    .content {
      text-align: center;
      flex-direction: column;
    }

    .questions-btn {
      text-align: center;
      display: inline-block;
      width: 100%;
      margin-top: 30px;

      button {
        margin-left: 0px;
      }
    }
  }

  .questions-section .questions-card img {
    height: 70px;
  }
  .unlimited-cube-container {
    width: 168px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .questions-section .questions-card::before {
    right: -150px;
    bottom: -140px;
    content: "";
  }

  .mobile-hide,
  .solid-border {
    display: none !important;
  }

  .mobile-date-with-published {
    display: block !important;
    margin-bottom: 15px;
  }

  .blog-post-slider-section {
    .blog-user {
      margin-top: 20px;
      width: 100%;

      h6 {
        flex: 1;
      }
    }
  }

  .footer-accordion {
    display: block !important;
  }

  .copyright {
    flex-direction: column;
    align-items: unset !important;
  }

  .theme-footer .scroll-btn {
    position: absolute;
    right: 20px;
    bottom: 30px;
  }

  .copyright p {
    margin-top: 15px;
  }

  .theme-footer .top-scroll-section {
    margin-top: 40px;
  }

  .email-input {
    margin-top: 30px;
  }

  .database-image img {
    position: absolute;
    bottom: -130px;
    left: 0;
    z-index: 1;
  }

  .database-card-row {
    position: relative;
    overflow: hidden;
    padding-bottom: 15px;
    z-index: 9;
  }

  .database-workload-start {
    padding: 170px 0px 0px;
  }

  .watch-btn {
    margin-left: 0px;
    position: relative;
    z-index: 9;

    a {
      width: 100%;
    }
  }

  .team-tursio-section {
    display: none;
    margin-right: 10px;
  }

  .join-tursio-section::before {
    width: 32%;
    left: -10%;
  }

  .join-tursio-section::after {
    width: 120%;
    height: 27%;
    left: 20px;
  }

  .join-tursio-section {
    padding: 15px 0px;

    .theme-title {
      text-align: center;
    }
  }

  .join-tursio-section .join-btn {
    display: unset;
    text-align: center;
    position: relative;
    z-index: 9;
    margin-top: 25px;
  }

  .meet-our-section {
    padding: 15px 0 !important;

    .theme-title {
      margin-bottom: 20px;

      h2 {
        text-align: center;
      }
    }
  }

  .meet-our-card {
    .user-image {
      img {
        height: 150px;
      }
    }

    .user-name {
      h4 {
        font-size: 20px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  .faq-section .theme-title {
    margin-bottom: 0px;
  }

  .research-pagination,
  .blog-pagination {
    .pagination {
      justify-content: space-between !important;

      .page {
        display: none;
      }
    }
  }

  .blog-pagination {
    .pagination {
      justify-content: space-between !important;

      .page {
        display: none;
      }
    }
  }

  .research-card-section .research-card h3 {
    font-size: 18px !important;
    line-height: 30px;
  }

  .blog-slider-section {
    background: transparent;
    padding: 0 !important;
  }
  .blog-body-section {
    margin-top: 15px !important;
  }
  .blog-body-section .blog-nav-body .tab-content {
    margin-top: 15px !important;
  }
  .case-studies-section .case-studies-card {
    flex-direction: column-reverse;
    padding: 10px;
  }

  .customer-slider {
    padding: 0px 0px 0px;
  }

  .enterprises-body {
    .enterprises-img-two {
      top: 44%;
      left: 48%;
      height: 120px;
    }

    .enterprises-img-three {
      top: 38% !important;
      left: 8% !important;
      width: 90px !important;
    }

    .enterprises-img-four {
      top: 47% !important;
      left: 66% !important;
      width: 100px !important;
    }

    .enterprises-img-five {
      top: 44% !important;
      left: 75% !important;
    }

    @keyframes animationTwo {
      to {
        transform: translateY(-100px) rotate(37deg);
      }
    }

    @keyframes animationThree {
      to {
        transform: translateY(100px) translateX(100px) rotate(187deg);
      }
    }

    @keyframes animationFour {
      to {
        transform: translateX(100px) rotate(28deg);
      }
    }

    @keyframes animationFive {
      to {
        transform: translateY(-100px) rotate(239deg);
      }
    }
  }

  .unlimited-cube-container {
    position: relative !important;
    padding: 13px 0 10px !important;

    .cube-img-one {
      position: relative;
      left: 0px;
    }
  }
}


@media screen and (max-width: 375px) {
  .enterprises-body {
    height: 400px;

    .enterprises-img {
      position: absolute;
      top: 50%;
      height: 160px;
    }

    .enterprises-img-two {
      top: 50%;
      left: 50%;
      height: 100px;
    }

    .enterprises-img-three {
      top: 32% !important;
      left: 2% !important;
      width: 75px !important;
    }

    .enterprises-img-four {
      top: 42% !important;
      left: 59% !important;
      width: 80px !important;
    }

    .enterprises-img-five {
      top: 46% !important;
      left: 76% !important;
      height: 40px;
    }

    @keyframes animationTwo {
      to {
        transform: translateY(-100px) rotate(31deg);
      }
    }

    @keyframes animationThree {
      to {
        transform: translateY(100px) translateX(100px) rotate(215deg);
      }
    }

    @keyframes animationFour {
      to {
        transform: translateX(100px) rotate(28deg);
      }
    }

    @keyframes animationFive {
      to {
        transform: translateY(-100px) rotate(210deg);
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .research-pagination .pagination .page button {
    padding: 3px 8px !important;
    font-size: 12px !important;
  }
  .research-pagination .pagination .page .previous-next-btn img {
    margin: 0 3px !important;
    width: 7px !important;
  }
  .research-pagination .pagination .page .previous-next-btn {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 992px) {
  .enterprises-body {
    display: none !important;
  }
  .enterprises-database .machines-card {
    margin-top: 20px !important;
  }
  .database-image {
    display: none !important;
  }
  .c-animeted-grfics {
    display: none !important;
  }
  .join-cube-tow, .join-cube-one {
    display: none;
  }
  .about-slide-cube {
    display: none;
  }

  .questions-section {
    padding: 0 0px 15px !important;
  }
  .questions-section .questions-card {
    padding: 20px 30px !important;
  }
  .database-naturally-section {
    margin-bottom: 0 !important;
  }
}

.embed-responsive {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.margin-top {
  margin-top: 20px;
}

.create-store-body{
  overflow: hidden;
  min-height: calc(100vh - 0px);
  display: flex;
  align-items: center;
  position: relative;
  .store-logo {
    height: 93px;
    margin-bottom: 50px;
  }
}
.create-account-tab {
  display: flex;
  align-items: center;
  .account {
    //margin-left: 30px;
     p {
      font-size: 18px !important;
      margin-top: 10px;
       margin-bottom: 0px;
    }
  }
  h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px !important;
  }
}

.security-tab {
  padding: 35px 0px;
  position: relative;
}

.security-tab::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 50px;
  background-color: #EFEFEF;
  top: -8px;
  left: 65px;
  border-radius: 100px;
}
.security-tab::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 50px;
  background-color: #EFEFEF;
  bottom: -8px;
  left: 65px;
  border-radius: 100px;
}

.account-form {
  position: relative;
  padding: 30px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.error-message {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-left: 30px;
  margin-top: 8px;
  min-height: 1.5rem;
}

.account-form input {
  padding: 10px 0px;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid gainsboro;
  margin-bottom: 30px;
  color: white;
  font-size: 18px;
}

.account-form ::placeholder {
  color: #85A7FF !important;
}

.account {
  padding-right: 150px;
}

.create-store-body::before {
  content: "";
  width: 45%;
  position: absolute;
  height: 100%;
  background-color: #070974;
  right: 0;
  z-index: -1;
}

.account-form label {
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
  display: inline-block;
}

.account-form button {
  border: none;
  height: 60px;
  text-transform: capitalize;
  color: #000672;
  font-size: 18px;
  font-weight: 700 !important;
}

.half-logo {
  position: absolute;
  top: 0;
  left: 55%;
  transform: translate(-50%, 0);
  height: 180px;
  z-index: -1;
}

.hexagon-section {
  position: absolute;
  left: 55%;
  bottom: -90px;
  transform: translate(-23%, 0px);
  z-index: -1;
  min-width: 622px;
}

.hexagon-2{
  -webkit-animation: slide-top 0.7s ease-in-out 100ms infinite alternate-reverse ;
  animation: slide-top 0.7s ease-in-out 100ms infinite alternate-reverse ;
  position: absolute;
  left: 38%;
  top: 27%;
}

img.hexagon-3 {
  -webkit-animation: slide-top 0.8s ease-in-out 80ms infinite alternate-reverse ;
  animation: slide-top 0.8s ease-in-out 80ms infinite alternate-reverse ;
  position: absolute;
  right: 18%;
  top: 48%;
}

@media (min-width: 1921px) {
  .create-account-tab h4 {
    font-size: 30px !important;
  }
  .create-account-tab .account p {
    font-size: 22px !important;
    margin-top: 14px;
    margin-bottom: 0px;
  }
  .security-img img {
    height: 160px;
  }
  .security-tab::before {
    left: 80px;
  }
  .security-tab::after {
    left: 80px;
  }
  .create-store-body .store-logo {
    height: 100px;
    margin-bottom: 50px;
  }
  .half-logo {
    height: 250px;
  }
  .account-form label {
    font-size: 28px;
  }
  .account-form input {
    padding: 20px 0px;
    font-size: 20px;
  }
  .account-form button {
    height: 70px;
    font-size: 23px;
  }
  .hexagon-section {
    position: absolute;
    left: 54%;
    bottom: -128px;
    z-index: -1;
    min-width: 800px;
  }
  .hexagon-1 {
    height: 500px;
  }
  .hexagon-2 {
    left: 40% !important;
    top: 27% !important;
    height: 360px !important;
  }
  .hexagon-3 {
    right: 0% !important;
    top: 44% !important;
    height: 260px !important;
  }
}

@media (max-width: 1920px) {
  .hexagon-1, .hexagon-2, .hexagon-3{
    width: unset;
  }
}

@media (min-width: 1450px) {
  .create-store-container{
    max-width: 75%;
  }
  .account {
    padding-right: 37%;
  }

}

.login-btn{
  padding: 10px 20px !important;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@media screen and (max-width: 1280px) {
  .account {
    padding-right: 50px;
  }
}

@media screen and (max-width: 991px) {
  .account {
    padding-right: 0px;
  }
  .create-store-body{
    height: unset !important;
  }
  .create-store-body::before{
    display: none;
  }
  .half-logo {
    height: 120px;
  }
  .store-logo {
    height: 65px;
    margin-bottom: 20px;
    margin-top: 140px;
  }
  .create-account-tab .account p {
    font-size: 15px !important;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .create-account-tab h4 {
    font-size: 22px !important;
  }
  .account-form{
    background-color: #070974;
  }
  .hexagon-section {
    display: none;
  }
  .store-logo {
    height: 76px !important;
  }
}

.tabs {
  position: relative; /* Needed for the absolute slider */
  display: flex;
  border-bottom: 0.5px solid #ccc;
  justify-content: left;
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #777;
}

.tab-item:hover {
  color: #007bff;
}

.tab-item.active {
  color: #007bff;
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}

.slider {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #007bff;
  transition: left 0.3s ease;
}

.custom-card{
  padding: 2em;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  min-height: max-content;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 30px -5px;
  transition: box-shadow 0.3s, transform 0.3s;
}

.custom-card:hover{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 40px -5px;
  transition: box-shadow 0.3s, transform 0.3s;
}

.solution-icon {
  height: 2.4em;
  margin-bottom: 1em;
}

.benefit-icon {
  height: 8em;
  margin-bottom: 1em;
}

.solution-header-vectors{
  position: relative;
  height: 500px;
  overflow: hidden;
  transform: translate(36%, 0);

  .enterprises-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .enterprises-img-two {
    position: absolute;
    top: 20%;
    left: 47%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .enterprises-img-three {
    transform: translate(-50%, 0%);
    position: absolute;
    top: 34%;
    left: 11%;
  }

  .enterprises-img-four {
    position: absolute;
    top: 30%;
    left: 70%;
    transform: translate(-50%, 0%);
  }

  .enterprises-img-five {
    transform: translate(-50%, 0%);
    top: 15%;
    left: 90%;
    position: absolute;
  }
}

.questions-btn{
  margin-top: 4em;
  text-align: center;
}

.questions-btn .btn {
  background-color: #016EC4;
  border-radius: 0.5em;
}

.questions-btn .btn:hover {
  background-color: #0561a7;
  color: white;
  border-radius: 0.5em;
}

.cta-content{
  justify-items: center;
}