.news-detail-container {
    padding: 20px;
    margin-top: 20px;
  }
  
  .theme-title {
    text-align: left;
  }
  
  .news-title {
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .news-author {
    font-style: italic;
    color: #555;
    margin-bottom: 15px;
    margin-left: 0.5%;
  }
  
  .news-content {
    font-weight: normal;
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
  }
  