.webinars-page {
  .webinars-card-section {
    margin-top: 20px;
    .webinars-inner-container {
      display: flex;
      align-items: center;
    }

    .webinars-search-header {
      padding: 20px 27px;
      background-color: transparent;
      margin-bottom: 40px;

      .webinars-search {
        position: relative;
        flex-basis: 70%;
        margin-right: 25px;

        img {
          position: absolute;
          left: 13px;
          top: 11px;
        }

        input {
          padding: 12px 10px 12px 50px;
          border-bottom: 1px solid rgba(0, 0, 0, 1);
          width: 100%;
          background-color: transparent;
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
        }
      }
    }

    .webinars-dropdown {
      flex-basis: 30%;

      .webinars-btn {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 1);
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 0px;
        background-color: transparent;
        border-left: 0px;
        border-top: 0px;
        border-right: 0px;

        &::after {
          display: none !important;
        }
      }
    }

    // Webinar Card
    .webinar-card {
      border: 1px solid rgba(188, 188, 188, 1);
      padding: 25px;
      transition: all 0.3s ease;
      height: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer !important;
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transform: translateY(-3px);
        cursor: pointer !important;
      }
      
      // Updated badge styles with different states
      .webinar-badge {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        font-weight: 600;
        padding: 5px 15px;
        border-radius: 0 0 0 15px;
        
        &.upcoming {
          background-color: #0057ff; // Blue for upcoming
          color: white;
        }
        
        &.live {
          background-color: #00b31b; // Green for live
          color: white;
        }
        
        &.past {
          background-color: #6c757d; // Gray for past
          color: white;
        }
      }
      
      h3 {
        font-size: 22px !important;
        font-weight: 500;
        line-height: 32px;
        margin-top: 10px;
        margin-bottom: 15px;
        color: #333;
      }
      
      .webinar-speakers {
        font-size: 15px !important;
        color: #333;
        margin-bottom: 10px;
        font-weight: 500;
      }
      
      .webinar-datetime {
        font-size: 14px !important;
        color: #666;
        margin-bottom: 15px;
        display: flex;
        gap: 8px;
        align-items: center;
      }
      
      .webinar-description {
        font-size: 15px !important;
        color: rgba(108, 117, 125, 1);
        margin-bottom: 20px;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
      
      .read-more-btn {
        margin-top: auto;
        align-self: start;
        background: transparent;
        color: var(--primary, #0057ff);
        font-weight: 600;
        cursor: pointer;
        border: none;
        text-align: left;
        padding: 0;
        transition: color 0.2s;
        
        &:hover {
          color: darken(#0057ff, 15%);
          text-decoration: underline;
        }
      }
    }
  }

  // Pagination styling to match research pagination
  .webinars-pagination {
    border-top: 0px;
    margin-top: 10px;

    .pagination {
      justify-content: center;

      .page {
        display: flex !important;
        color: var(--blackColor) !important;

        a {
          color: black;
          text-decoration: unset;
          font-weight: 600;

          img {
            margin-right: 10px;
          }

        }

        .previous-next-btn {
          display: flex;
          align-items: center;

          img {
            margin: 0px 10px;
          }
        }

        button {
          background-color: transparent;
          color: var(--blackColor) !important;
          border: unset !important;
        }
      }

      .active {
        .page-link {
          background-color: rgba(249, 245, 255, 1);
          color: rgba(57, 143, 255, 1) !important;
        }
      }
    }
  }

  // Title styling
  .title-left {
    font-size: 28px;
    font-weight: 600;
    color: #333;
    
    span {
      position: relative;
      bottom: -10px;
    }
  }

  @media screen and (max-width: 767px) {
    .webinars-inner-container {
      flex-direction: column-reverse;
    }
  }
}